<script>
  import SlimScrollContainer from "./SlimScrollContainer.svelte";
  import DropdownMenu from "../common/DropdownMenu.svelte";
  import { sortedLocations } from "../../store/sortedLocations";
  import { isFavourites } from "../../store/main";
</script>

<div>
  <div class="md:mt-3 mt-5 md:mb-10 mb-5">
    <div class="">
      <div class="text-base md:text-2xl font-bold">
        {#if $isFavourites}
          Favourite
        {:else}
          Practices that meet your criteria
        {/if}
      </div>
    </div>

    <div class="flex justify-between items-center">
      <div class="flex items-center">
        {#if $sortedLocations}
          <p class="">{$sortedLocations.length} results</p>
        {/if}
      </div>

      <div class="flex justify-between items-center lg:relative xl:bottom-9">
        {#if !$isFavourites}
          <div class="flex items-center">
            <span class="text-center mr-4 relative">Sort by</span>
            <div class="text-center ">
              <DropdownMenu />
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
  <hr class="bg-light-grey w-full mb-4" />
  <div>
    <SlimScrollContainer sortedItems={$sortedLocations} />
  </div>
</div>

import { derived } from "svelte/store";

import { getApiStaticData } from "../openApiConfig";
import { placementTypesFiltersChecked } from "../main";
import type { APIPlacementType } from "@EMSXVET/emsx-fetch-map-app/dist/models";

type APIFiltersWithChecked = APIPlacementType & {
  isChecked: boolean;
  filterGroup: string;
};

export const getStaticPlacementTypes = derived(
  [placementTypesFiltersChecked],
  ([$checkList], set) => {
    getApiStaticData()
      .staticPlacementTypesApiStaticDataPlacementTypesGet()
      .then(response => {
        let items: APIFiltersWithChecked[] = JSON.parse(
          JSON.stringify(response.items),
        );
        items = items.map(item => {
          if ($checkList.includes(item.id)) {
            item = { ...item, isChecked: true, filterGroup: "placementTypes" };
          } else {
            item = { ...item, isChecked: false, filterGroup: "placementTypes" };
          }
          return item;
        });
        set(items);
      })
      .catch(error => {
        console.log(error);
        set([]);
      });
  },
);

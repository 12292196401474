<script>
    import SearchInput from "../common/SearchInput.svelte";
    import {
        placementTypesFiltersChecked,
        speciesFilterChecked,
        practiceInterestsFiltersChecked,
    } from "../../store/main";

    import {getStaticPlacementTypes} from "../../store/filters/getStaticPlacementTypes";
    import {getStaticPracticeInterests} from "../../store/filters/getStaticPracticeInterests";
    import {getStaticSpecies} from "../../store/filters/getStaticSpecies";

    export let showModal;

    const handleRemovePlacementTypeFilter = anId => {
        const newFilter = $placementTypesFiltersChecked.filter(id => id !== anId);
        $placementTypesFiltersChecked = newFilter;
    };

    const handleRemoveSpeciesFilter = anId => {
        const newFilter = $speciesFilterChecked.filter(id => id !== anId);
        $speciesFilterChecked = newFilter;
    };

    const handleRemovePracticeInterestFilter = anId => {
        const newFilter = $practiceInterestsFiltersChecked.filter(
            id => id !== anId,
        );
        $practiceInterestsFiltersChecked = newFilter;
    };
</script>

<div class="block">
    <SearchInput/>
    <div class="justify-center md:justify-start md:flex-wrap flex mt-4 mb-4 md:mb-9">
        {#if $getStaticPlacementTypes}
            {#each $getStaticPlacementTypes as filter}
                {#if filter.isChecked}
          <span
                  class="hidden md:block rounded-full ml-0 m-2.5 px-5 py-2 bg-light-grey bg-opacity-50"
          >
            {filter.type}
              <span
                      on:click={() => handleRemovePlacementTypeFilter(filter.id)}
                      class="p-2 font-bold text-sm cursor-pointer "
              >
              <i class="fal fa-times"/>
            </span>
          </span>
                {/if}
            {/each}
        {/if}
        {#if $getStaticSpecies}
            {#each $getStaticSpecies as filter}
                {#if filter.isChecked}
          <span
                  class="hidden md:block rounded-full ml-0 m-2.5 px-5 py-2 bg-light-grey bg-opacity-50"
          >
            {filter.name}
              <span
                      on:click={() => handleRemoveSpeciesFilter(filter.id)}
                      class="p-2 font-bold text-sm cursor-pointer"
              >
              <i class="fal fa-times"/>
            </span>
          </span>
                {/if}
            {/each}
        {/if}
        {#if $getStaticPracticeInterests}
            {#each $getStaticPracticeInterests as filter}
                {#if filter.isChecked}
          <span
                  class="hidden md:block rounded-full ml-0 m-2.5 px-5 py-2 bg-light-grey bg-opacity-50"
          >
            {filter.name}
              <span
                      on:click={() => handleRemovePracticeInterestFilter(filter.id)}
                      class="p-2 font-bold text-sm cursor-pointer"
              >
              <i class="fal fa-times"/>
            </span>
          </span>
                {/if}
            {/each}
        {/if}
        <div class="relative">
            <button
                    class="bg-light-grey font-bold outline-none rounded-full ml-0 m-2.5 px-5 py-2 cursor-pointer"
                    on:click={() => (showModal = true)}
            >
                More filters
            </button>
            {#if $placementTypesFiltersChecked.length > 0 || $speciesFilterChecked.length > 0 ||
            $practiceInterestsFiltersChecked.length > 0}
                <div class="bg-red-600 w-3 h-3 rounded-full top-2 absolute right-2 md:hidden">
                </div>
            {/if}
        </div>
    </div>
</div>

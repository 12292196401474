<script>
  import { address } from "../../store/main";
  import { getGeoLocationByAddress } from "../../store/getGeoLocationByAddress";
  import { afterUpdate, onMount } from "svelte";
  import { getStaticPlacementTypes } from "../../store/filters/getStaticPlacementTypes";

  let locationValueInputText = "";
  let placementTypeValueInputText = "";
  let selectedCityFromStorage;
  let currentSelectedPlacementTypeName = "";
  let currentAvailablePlacementTypes = [];
  let selectedPlacementTypeId;
  let showPlacementList = true;
  let placementTypeInputField;
  let urlEMSXSection;
  let timer;

  const debounce = v => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      $address = v;
    }, 750);
  };

  afterUpdate(() => {
    /*
        Note to future self: because of the EMSXSearch bar in the django home page, pretty much the entire svelte-map gets called onMount and updates and renders on the home page. See JS there on how it has been hidden.
        Because of all of this, we can't use onMount and any other hook to transfer information across properly.
        Thus the use of localStorage below.
         */
    selectedCityFromStorage = localStorage.getItem("currentCitySelected");
  });

  onMount(() => {
    urlEMSXSection = JSON.parse(
      document.getElementById("emsx-location-page-data").textContent,
    );
  });

  const handleSubmit = () => {
    const currentCityInLocalStorage = localStorage.getItem(
      "currentCitySelected",
    );
    locationValueInputText = $getGeoLocationByAddress.formattedAddress;

    let currentLatLng = $getGeoLocationByAddress.geometry;
    if (currentCityInLocalStorage && locationValueInputText === "") {
      locationValueInputText = currentCityInLocalStorage;
      currentLatLng = JSON.parse(localStorage.getItem("currentCityCoords"));
    }
    localStorage.setItem("currentCitySelected", locationValueInputText);
    localStorage.setItem("currentCityCoords", JSON.stringify(currentLatLng));
    window.location = generateUrl(currentLatLng, selectedPlacementTypeId);
  };

  const generateUrl = (currentLatLng, placementTypeId) => {
    const regexTarget = /\./gi;
    const lat = currentLatLng.lat.toString().replace(regexTarget, "x");
    const lng = currentLatLng.lng.toString().replace(regexTarget, "x");
    const urlMapSectionWithoutPT = `map/${lat}/${lng}/x/x/x`;
    const urlMapSectionWithPT = `map/${lat}/${lng}/${placementTypeId}/x/x`;
    if (selectedPlacementTypeId) return urlEMSXSection + urlMapSectionWithPT;
    return urlEMSXSection + urlMapSectionWithoutPT;
  };

  const handleLocationInputChange = e => {
    debounce(e.target.value);
    // $address = e.target.value;
  };

  const handleLocationInputOnfocus = () => {
    $address = "";
    selectedCityFromStorage = "";
    localStorage.setItem("currentCitySelected", "");
  };

  const handlePlacementTypeInputChange = e => {
    showPlacementList = true;
    const userInput = e.target.value.toLowerCase();
    const availablePlacementTypes = [];
    let availablePlaces = [];
    $getStaticPlacementTypes.forEach(type => {
      const obj = {
        type: type.type.toLowerCase(),
        id: type.id,
      };
      availablePlacementTypes.push(obj);
    });

    availablePlacementTypes.forEach(placement => {
      if (placement.type.indexOf(userInput) > -1) {
        const obj = {
          type:
            placement.type.charAt(0).toUpperCase() + placement.type.slice(1),
          id: placement.id,
        };
        availablePlaces.push(obj);
      }
    });
    currentAvailablePlacementTypes = availablePlaces;
  };

  const selectPlacementType = (type, id) => {
    selectedPlacementTypeId = id;
    showPlacementList = false;
    currentSelectedPlacementTypeName = type;
  };

  const handlePlacementInputFieldClick = () => {
    placementTypeValueInputText = "";
    placementTypeInputField.focus();
  };
</script>

<div class="flex justify-center md:block">
  <form
    on:submit|preventDefault={handleSubmit}
    class="w-full flex md:items-center justify-between flex-row items-center gap-4 md:gap-2 lg:gap-4 bg-light-grey rounded-full p-4 md:pb-5 lg:pb-4"
  >
    <section
      class="flex w-full flex-col lg:flex-row gap-1 md:gap-2 lg:gap-4 items-center md:h-full pl-4 md:pl-2 lg:pl-0"
    >
      <div
        class=" inline-block lg:px-15px md:py-0 md:pl-0 show-placeholder w-full min-w-150px"
      >
        <div class="lg:pl-2">
          <div class="relative h-24px w-full  truncate md:left-12px lg:left-0">
            <input
              class="show-placeholder w-full md:w-150px lg:w-full outline-none focus:online-none focus:border-none absolute h-24px bg-light-grey"
              on:keyup={e => handleLocationInputChange(e)}
              bind:value={locationValueInputText}
              on:focus={handleLocationInputOnfocus}
              type="text"
              id="inputSearchFormSvelteMap"
              placeholder="Location"
            />
            {#if true}
              <label
                on:click={() => (locationValueInputText = "")}
                class=" focus:online-none focus:border-none absolute truncate bg-light-grey md:w-180px lg:w-full"
                for="inputSearchFormSvelteMap"
              >
                {#if selectedCityFromStorage}
                  {selectedCityFromStorage}
                {:else if $getGeoLocationByAddress.formattedAddress}
                  {$getGeoLocationByAddress.formattedAddress}
                {:else}
                  {locationValueInputText}
                {/if}
              </label>
            {/if}
          </div>
        </div>
      </div>
      <div
        class="mt-10px md:mt-0 w-full lg:w-1px border-b md:border-l lg:border-b-41px border-gray-300"
      />
      <div
        class=" inline-block md:pr-10px md:py-0 md:pl-0 lg:mr-10px show-placeholder w-full"
      >
        <div class="">
          <div class="relative h-24px w-full top-2px mb-2 md:mb-0 md:left-5px">
            <input
              class="show-placeholder w-full outline-none focus:online-none focus:border-none absolute bg-light-grey md:pl-2 lg:pl-0"
              bind:this={placementTypeInputField}
              on:input={e => handlePlacementTypeInputChange(e)}
              bind:value={placementTypeValueInputText}
              type="text"
              placeholder="Placement Type"
            />
            {#if placementTypeValueInputText}
              <label
                on:click={handlePlacementInputFieldClick}
                class=" focus:online-none focus:border-none absolute truncate bg-light-grey left-0 whitespace-pre-wrap lg:whitespace-nowrap lg:w-150px xl:w-full text-15px"
                for="thisIdDoesNotMatterButMustBeHere4"
              >
                {currentSelectedPlacementTypeName}
              </label>
            {/if}
            {#if currentAvailablePlacementTypes.length > 0 && showPlacementList}
              <i
                class="fal fa-chevron-down absolute right-0 top-8px text-dark-grey"
              />
              <div
                class="bg-white absolute top-10px mt-4 w-full min-w-180px left-2px lg:left-0 lg:right-10px"
              >
                <ul class="border-grey-300 border-1px bg-white relative z-10">
                  {#each currentAvailablePlacementTypes as types}
                    <li
                      on:click={() => selectPlacementType(types.type, types.id)}
                      class="text-16px cursor-pointer hover:bg-light-grey p-2"
                    >
                      {types.type}
                    </li>
                  {/each}
                </ul>
              </div>
            {/if}
          </div>
        </div>
      </div>
    </section>
    <div
      class="h-100px md:h-81px lg:h-full flex items-center border-l border-gray-300"
    >
      <button
        class="form-title rounded-full py-1 px-15px py-10px mx-3 bg-light-blue font-medium"
        type="submit"
        ><p class="hidden md:block">Search</p>
        <div class="block md:hidden">
          <i class="fas fa-search" />
        </div>
      </button>
    </div>
  </form>
</div>

import axios from "axios";
import { isFavHandlerReady } from "../store/favourites/isFavHandlerReady";
import { favouriteList } from "../store/favourites/favouriteList";

export const removeFavouritesApi = (id: number): any => {
  axios
    .get(`/ajax/student/favourites/remove/${id}/`, {
      withCredentials: true,
    })
    .then(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isFavHandlerReady.update(_ => true);
      // remove favLocation from favList
      favouriteList.update(data => {
        return data.filter(anId => anId !== id);
      });
    })
    .catch(error => {
      console.log(error);
    })
    .then(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isFavHandlerReady.update(_ => true);
    });
};

import { derived } from "svelte/store";

import { getApiStaticData } from "../openApiConfig";
import { practiceInterestsFiltersChecked } from "../main";
import type { APIPracticeInterests } from "@EMSXVET/emsx-fetch-map-app/dist/models";

type APIPracticeInterestsWithChecked = APIPracticeInterests & {
  isChecked: boolean;
  filterGroup: string;
};

export const getStaticPracticeInterests = derived(
  [practiceInterestsFiltersChecked],
  ([$checkList], set) => {
    getApiStaticData()
      .staticPracticeInterestsApiStaticDataPracticeInterestsGet()
      .then(response => {
        let items: APIPracticeInterestsWithChecked[] = JSON.parse(
          JSON.stringify(response.items),
        );
        items = items.map(item => {
          if ($checkList.includes(item.id)) {
            item = {
              ...item,
              isChecked: true,
              filterGroup: "practiceInterests",
            };
          } else {
            item = {
              ...item,
              isChecked: false,
              filterGroup: "practiceInterests",
            };
          }
          return item;
        });
        set(items);
      })
      .catch(error => {
        console.log(error);
        set([]);
      });
  },
);

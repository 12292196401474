<script lang="ts">
  import TailwindLoader from "./loaders/TailwindLoader.svelte";
  import { Router } from "svelte-routing";
  import FontAwesomeLoader from "./loaders/FontAwesomeLoader.svelte";

  import { isFavourites } from "./store/main";

  import Home from "./pages/Home.svelte";

  let docUrl = new URL(document.URL);
  let baseUrl = docUrl.pathname;

  $isFavourites = baseUrl.includes("/favourites");
  export let url = "";
</script>

<TailwindLoader />
<FontAwesomeLoader />
<Router {url} basepath={baseUrl}>
  <div id="main-app">
    <Home />
  </div>
</Router>

<script>
  import { isMapFullScreen } from "../../store/main";

  const expandMap = () => {
    $isMapFullScreen = true;
  };
  const contractMap = () => {
    $isMapFullScreen = false;
  };
</script>

<div class="relative hidden md:block">
  {#if $isMapFullScreen}
    <div
      class="absolute top-4 left-4 text-white cursor-pointer z-10 bg-emsx-blue w-32 h-9 flex justify-center items-center rounded-full"
      on:click={contractMap}
    >
      <i class="far fa-chevron-right text-xs " />
      <span class="ml-2">Show List</span>
    </div>
  {:else}
    <div
      class="absolute top-4 left-4 cursor-pointer z-10 bg-emsx-blue w-9 h-9 flex justify-center items-center rounded-full"
      on:click={expandMap}
    >
      <i class="far fa-chevron-left text-white" />
    </div>
  {/if}
</div>

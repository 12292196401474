<script>
  import {
    selectedLocation,
    allowMarkerBounce,
    isFavourites,
  } from "../../store/main";
  import FCarousel from "../common/FCarousel.svelte";
  import { getStaticPlacementTypes } from "../../store/filters/getStaticPlacementTypes";
  import { getStaticSpecies } from "../../store/filters/getStaticSpecies";
  import { getStaticPracticeInterests } from "../../store/filters/getStaticPracticeInterests";
  import CamelIcon from "../../../assets/svgs/CamelIcon.svelte";

  export let id;
  export let placement_provider_name;
  export let address1;
  export let latitude;
  export let longitude;
  export let city;
  export let post_code;
  export let url;
  export let website;

  export let distance;
  export let species_ids;
  export let placement_types_ids;
  export let practice_interests_ids;
  export let rcvs_accredited;
  const rcvsLogoSrc = "/static/django/img/rcvs_logo.png";

  const handleCardClick = () => {
    window.location.href = url;
  };

  const handleMouseOver = id => {
    $allowMarkerBounce = true;
    $selectedLocation = { id, placement_provider_name, latitude, longitude };
  };
</script>

<div
  class="flex flex-col md:flex-row md:border-b-2 md:border-gray-200 mb-3 cursor-pointer md:pb-8"
  on:mouseenter={() => handleMouseOver(id)}
>
  <div class="z-0">
    <FCarousel bind:id />
  </div>
  <div class="p-2 md:p-4 w-full flex-grow" on:click={() => handleCardClick()}>
    {#if !placement_types_ids.length}
      <p class="bg-light-grey inline py-1.5 px-3 rounded-full italic text-xs">
        General practice
      </p>
    {:else if placement_types_ids.length}
      {#if $getStaticPlacementTypes}
        {#each $getStaticPlacementTypes as pt}
          {#if placement_types_ids.includes(pt.id)}
            <p
              class="bg-light-grey inline py-1.5 px-3 rounded-full italic text-xs"
            >
              {pt.type}
            </p>
          {/if}
        {/each}
      {/if}
    {/if}
    <div class="text-base mt-2 mb-1 font-bold leading-6">
      {placement_provider_name}
    </div>
    <p class="hidden">{website}</p>
    <p class="text-base">
      {address1}{#if city}, {city.name}{/if}{#if post_code}, {post_code}{/if}
    </p>
    <div class="grid grid-cols-2 mt-1">
      <div class="m-1 inline">
        <div class="flex flex-col">
          {#if species_ids.length}
            {#if $getStaticSpecies}
              {#each $getStaticSpecies as sp}
                {#if species_ids.includes(sp.id)}
                  <div>
                    {#if sp.id === 3}
                      <i class="fas">
                        <CamelIcon />
                      </i>
                    {:else}
                      <i class={sp.fontawesome_name} />
                    {/if}
                    <span class="ml-2 mb-2 text-sm"> {sp.name} </span>
                  </div>
                {/if}
              {/each}
            {/if}
          {/if}
        </div>
      </div>

      <div class="ml-2 md:ml-4">
        <div class="flex flex-col">
          {#if practice_interests_ids.length}
            {#if $getStaticPracticeInterests}
              {#each $getStaticPracticeInterests as pi}
                {#if practice_interests_ids.includes(pi.id)}
                  <div>
                    <i class={pi.fontawesome_name} />
                    <span>
                      {pi.name}
                    </span>
                  </div>
                {/if}
              {/each}
            {/if}
          {/if}
        </div>
      </div>
    </div>
  </div>
  <div
    class="p-2 md:p-4 flex justify-end md:flex-wrap md:justify-around md:content-between"
  >
    {#if !$isFavourites}
      <div class="text-xs hidden md:block">
        {Math.round(distance.m / 1600)} miles
      </div>
    {/if}
    {#if rcvs_accredited}
      <div class="w-14 ">
        <img src={rcvsLogoSrc} alt="rcsv accredited logo" />
      </div>
    {/if}
  </div>
</div>

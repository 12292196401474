// Svelte Store
import { writable } from "svelte/store";

export const currentCitySelected = writable("");
export const selectedLocation = writable({});

export const placementTypesFiltersChecked = writable([]);
export const practiceInterestsFiltersChecked = writable([]);
export const speciesFilterChecked = writable([]);

export const mapCenterLatLng = writable({ lat: 120, lng: 0 });
export const currentSortMethod = writable("Name");

export const isMapFullScreen = writable(false);

export const address = writable("");
export const zoomToLocation = writable(false);

export const allowMarkerBounce = writable(false);
export const isFavourites = writable(false);

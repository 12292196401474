<script>
  import {isMapFullScreen} from "../../store/main"

  const isThisAMapPage = document.URL.includes("/map/")

  const toggleMapListView = () => {
    $isMapFullScreen = !$isMapFullScreen
  }
</script>

{#if isThisAMapPage}
  <div
      class="md:hidden primary-button fixed bottom-4 left-0 right-0 m-auto z-30"
      on:click={() => toggleMapListView()}
  >
    {#if $isMapFullScreen}
      List View
    {:else}
      Map View
    {/if}
  </div>
{/if}

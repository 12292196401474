import { derived } from "svelte/store";
import { getApiSearch } from "./openApiConfig";

import {
  mapCenterLatLng,
  placementTypesFiltersChecked,
  practiceInterestsFiltersChecked,
  speciesFilterChecked,
} from "./main";
import type {
  APIFilters,
  SearchVetLongLatApiSearchAreaLimitLongitudeLatitudeRadiusPostRequest,
} from "@EMSXVET/emsx-fetch-map-app";

// Array<APILocationPageDistance>
export const searchPlacementByLatLong = derived(
  [
    mapCenterLatLng,
    placementTypesFiltersChecked,
    speciesFilterChecked,
    practiceInterestsFiltersChecked,
  ],
  (
    [
      $mapCenter,
      $placementTypesFiltersChecked,
      $speciesFilterChecked,
      $practiceInterestsFiltersChecked,
    ],
    set,
  ) => {
    const filters: APIFilters = {
      practice_interests: $practiceInterestsFiltersChecked,
      placement_types: $placementTypesFiltersChecked,
      species: $speciesFilterChecked,
    };

    const req: SearchVetLongLatApiSearchAreaLimitLongitudeLatitudeRadiusPostRequest =
    {
      latitude: $mapCenter.lat,
      longitude: $mapCenter.lng,
      aPIFilters: filters,
      radius: 20,
    };

    getApiSearch()
      .searchVetLongLatApiSearchAreaLimitLongitudeLatitudeRadiusPost(req)
      .then(blob => {
        set(blob.items);
      })
      .catch(err => {
        console.log(`${err}`);
        set([]);
      });
  },
  [],
);

export const parseQueryStringFromUrl = (qString: string): any => {
  // QueryString should expect:  lat/lng/placementTypes/species/practiceInterests
  const qList = qString.split("/");
  const lat = parseFloat(qList[0].replace("x", "."));
  const lng = parseFloat(qList[1].replace("x", "."));
  const placementTypesStr: string[] = qList[2].split("-");
  let placementTypes: number[];
  if (placementTypesStr[0] === "x" || placementTypesStr[0] === "") {
    placementTypes = [];
  } else {
    placementTypes = qList[2].split("-").map(pt => parseInt(pt));
  }
  const speciesStr: string[] = qList[3].split("-");
  let species: number[];
  if (speciesStr[0] === "x" || speciesStr[0] === "") {
    species = [];
  } else {
    species = qList[3].split("-").map(sp => parseInt(sp));
  }
  const practiceInterestsStr: string[] = qList[4].split("-");
  let practiceInterests: number[];
  if (practiceInterestsStr[0] === "x" || practiceInterestsStr[0] === "") {
    practiceInterests = [];
  } else {
    practiceInterests = qList[4].split("-").map(pi => parseInt(pi));
  }
  return {
    coordinates: { lat, lng },
    placementTypes,
    species,
    practiceInterests,
  };
};

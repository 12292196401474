export const createInfoWindowHTML = props => {
  return `
  <div id="pp_info_window_content" class="pp_info_window">
    <button class="absolute top-0 right-0 m-2 focus:outline-none " >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" class="w-8 h-8">
        <path fill-rule="evenodd" d="M10.707 10l4.147 4.146a.5.5 0 1 1-.708.708L10 10.707l-4.146 4.147a.5.5 0 1 1-.708-.708L9.293 10l-4.147-4.146a.5.5 0 0 1 .708-.708L10 9.293l4.146-4.147a.5.5 0 0 1 .708.708L10.707 10z" clip-rule="evenodd" />
      </svg>
    </button>
    <img class="pp_info_window--image" src="//placehold.co/160x100" alt="Kitten 23">
    <span class="relative bottom-32 left-4">
      <i class="fas fa-heart" style=" font-size:1.3rem; color: #fff"></i>
    </span>
    <div class="m-4 mt-0 text-base w-56">
      <div class="font-bold mb-1">${props.placement_provider_name}</div>
      <div>${props.address1}</div>
      <div>${props.post_code}</div>
    </div>
  </div>
`;
};

// export const createInfoWindowHTML = props => {
//   return `
//   <div id="pp_info_window_content" class="pp_info_window">
//   <img class="pp_info_window--image" src="//placehold.co/160x100" alt="Kitten 23">
//   <span class="relative bottom-32 left-4">
//     <i class="fas fa-heart" style=" font-size:1.3rem; color: #fff"></i>
//     </span>
//     </img>
//     <div class="m-4 mt-0 text-base w-56">
//       <div class="font-bold mb-1">${props.placement_provider_name}</div>
//       <div>${props.address1}</div>
//       <div>${props.post_code}</div>
//     </div>
// </div>
// `;
// };

import { derived } from "svelte/store";
import {
  mapCenterLatLng,
  placementTypesFiltersChecked,
  practiceInterestsFiltersChecked,
  speciesFilterChecked,
} from "./main";

const cleanURL = () => {
  const currentURL = window.location.href;
  const beforeDomain = currentURL.split("map/")[0];
  window.history.replaceState("map", "coords", beforeDomain);
};

export const urlQueryString = derived(
  [
    mapCenterLatLng,
    placementTypesFiltersChecked,
    speciesFilterChecked,
    practiceInterestsFiltersChecked,
  ],
  ([$mapLatLng, $pT, $sP, $pI]) => {
    const nullSymble = "x";
    if (!$mapLatLng.lat || !$mapLatLng.lng) {
      // no lat or lng
      return;
    }

    const placementType = JSON.stringify($pT) === "[]" ? nullSymble : $pT;
    const species = JSON.stringify($sP) === "[]" ? nullSymble : $sP;
    const practiceInterests = JSON.stringify($pI) === "[]" ? nullSymble : $pI;

    const urlString = String(
      `map/${$mapLatLng.lat.toFixed(4)}/${$mapLatLng.lng.toFixed(
        4,
      )}/${placementType}/${species}/${practiceInterests}`,
    );
    const re = /\./gi;
    cleanURL();
    window.history.replaceState(null, "coords", urlString.replace(re, "x"));
  },
  "",
);

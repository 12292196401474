<script lang="ts">
  import ListContainer from "../components/listComponents/ListContainer.svelte";
  import Map from "../components/map/Map.svelte";

  import FilterContainer from "../components/filters/FilterContainer.svelte";
  import FilterModal from "../components/filters/FilterModal.svelte";
  import { onMount } from "svelte";

  import { urlQueryString } from "../store/urlQueryString";
  import { centerMapOnUrlQueryData } from "../utils/centerMapOnUrlQueryData";
  import MapListToggleButton from "../components/common/MapListToggleButton.svelte";
  import {
    isMapFullScreen,
    isFavourites,
  } from "../store/main";

  import MapExpandIcon from "../components/common/MapExpandIcon.svelte";

  let showModal = false;

  onMount(() => {
    centerMapOnUrlQueryData();
    $urlQueryString;
  });
</script>

<main
  class={$isMapFullScreen
    ? "max-h-screen overflow-hidden bg-white"
    : "w-screen bg-white"}
>
  <MapListToggleButton />
  <FilterModal bind:showModal />

  <div
    class={$isMapFullScreen
      ? ""
      : "grid grid-cols-1 md:grid-cols-2 h-5/6 w-full -z-1 container m-auto md:px-5 px-4"}
  >
    <div
      class={$isMapFullScreen
        ? "h-googleMapMediumScreenHeight"
        : "md:order-2 md:w-50vw"}
    >
      <MapExpandIcon />
      <Map />
    </div>
    <div class={$isMapFullScreen ? "hidden" : "py-4 md:pr-4"}>
      {#if !$isFavourites}
        <FilterContainer bind:showModal />
      {/if}
      <ListContainer />
    </div>
  </div>
</main>

<style>
  :global(.primary-button) {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    justify-content: center;
    border-radius: 20px;
    padding: 10px 25px;
    background-color: rgba(141, 217, 251, 1);
  }

  :global(.secondary-button) {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    text-align: left;
    line-height: 21px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    justify-content: center;
    border-radius: 20px;
    padding: 10px 25px;
    background-color: rgba(242, 242, 242, 1);
    max-width: fit-content;
  }

  :global(.-z-1) {
    z-index: -1;
  }

  :global(.width-inherit) {
    width: inherit;
  }

  :global(.width-85) {
    width: 84%;
  }

  /*Styling Google*/
  :global(.google-map button) {
    background: #383c50 !important;
  }

  :global(.gmnoprint div) {
    background: transparent !important;
    box-shadow: none !important;
  }

  :global(div .gm-svpc) {
    background: #383c50 !important;
  }

  :global(button.gm-control-active) {
    border-radius: 20px;
  }

  :global(.google-map button img) {
    filter: invert(1) contrast(10);
  }

  :global(.google-map button) :global(.gm-iv-address) {
    font-family: "Poppins" !important;
  }

  :global(.gmnoprint button) {
    color: white !important;
  }
  :global(.gm-iv-profile-link) {
    font-weight: 400 !important;
  }

  :global(button.gm-control-active.gm-compass-needle) {
    filter: invert(1);
    background: #c7c3af !important;
  }

  :global(.container) {
    width: auto !important;
  }

  @media only screen and (max-width: 640px) {
    :global(.container) {
      max-width: 610px !important;
    }
  }

  @media only screen and (max-width: 768px) {
    :global(.container) {
      max-width: 738px !important;
    }
  }

  @media only screen and (max-width: 1024px) {
    :global(.container) {
      max-width: 994px !important;
    }
  }

  @media only screen and (max-width: 1280px) {
    :global(.container) {
      max-width: 1250px !important;
    }
  }

  @media only screen and (max-width: 1536px) {
    :global(.container) {
      max-width: 1506px !important;
    }
  }
</style>

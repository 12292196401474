<svg width="18px" height="16px" viewBox="0 0 309.405 303.297" transform="scale(-1, 1)">
	<defs />
	<path
		id="shape0"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 58.7437608075658 3.97659897354276)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M25.1118 22.9768C25.1118 7.63479 34.2996 11.8686 24.0799 1.64889C18.4113 -4.01972 6.50287 6.53104 5.15997 9.21684C4.71223 10.1123 0 11.5954 0 11.9688"
	/><path
		id="shape1"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 25.2972943804426 15.9454257137921)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M33.4465 0C25.9259 0 16.4378 9.09668 11.7746 13.7599C8.74733 16.7872 4.62162 16.4423 0 16.71"
	/><path
		id="shape2"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 3.36009620442862 32.4729781394221)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M23.3967 0C9.67151 0 0 21.9595 0 34.3841C0 34.854 1.43096 38.8561 2.06399 38.8561"
	/><path
		id="shape3"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 7.14407328911506 70.2694404245385)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M0 2.43565C8.09328 2.43565 27.3903 -2.27309 34.7438 1.40366C37.5452 2.80438 44.4434 8.62761 45.4077 8.62761"
	/><path
		id="shape4"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 54.615785806089 78.8970444863012)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M0 0C0 38.2404 1.45488 67.1585 25.4558 91.1594C27.413 93.1166 34.5122 96.604 34.5288 100.13"
	/><path
		id="shape5"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 88.5428597433473 181.365419026322)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M0.515724 0C0.515724 16.7785 -1.32311 32.1657 1.94363 48.4995C4.39455 60.7541 17.0247 65.9611 17.0247 78.9049"
	/><path
		id="shape6"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 100.459463071972 260.270282271933)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M5.10811 0C3.87816 4.91979 7.58347 35.7568 0 35.7568"
	/><path
		id="shape7"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 88.5493395259132 296.209468776312)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M11.4845 0C8.89544 5.17803 -4.33854 13.6824 1.45067 13.6824"
	/><path
		id="shape8"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 90.0000032505514 308.4324461008)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M0 1.45946C13.5875 1.45946 28.0429 0 40.6216 0"
	/><path
		id="shape9"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 130.621626277929 245.189200668955)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M0 63.2432C6.20761 44.6204 0.243243 19.3899 0.243243 0"
	/><path
		id="shape10"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 130.864869529589 207.72973991317)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M0 37.4595C4.58165 29.8234 9.09743 19.0698 11.1892 10.7027C11.4855 9.51766 12.7788 0 13.6216 0"
	/><path
		id="shape11"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 144.486491622602 204.139202106365)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M0 3.59054C18.2137 3.59054 37.1897 13.2893 57.4054 10.4013C68.247 8.85255 81.3499 1.88783 91.7027 1.88783C92.577 1.88783 98.4527 -0.0830175 99.9122 0.00271828"
	/><path
		id="shape12"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 244.398646128542 204.141920395076)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M0 0C5.33283 0 24.0084 24.6505 31.4078 29.3822"
	/><path
		id="shape13"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 260.270279413211 234.000011092552)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M16.5405 0C25.6828 18.9725 0 43.843 0 61.7838"
	/><path
		id="shape14"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 249.102528900932 296.475709442004)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M10.278 0C-18.9541 17.0671 21.9807 10.9838 36.4651 10.9838"
	/><path
		id="shape15"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 285.567577585949 151.540548779492)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M0 155.919C0 131.378 12.3127 110.259 16.2973 86.3514C20.965 58.3451 17.7568 28.1054 17.7568 0"
	/><path
		id="shape16"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 196.054060974722 55.2162211217594)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M107.27 93.8919C107.27 51.3363 73.9482 60.3748 51.0811 42.0811C31.2321 26.2019 33.6364 0 0 0"
	/><path
		id="shape17"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 115.783787926612 55.2162211217594)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M80.2703 0C39.4892 0 40.07 63.973 0 63.973"
	/><path
		id="shape18"
		transform="matrix(1.00000002942963 0 0 1.00000002942963 83.4324354557063 28.7027066907169)"
		fill="none"
		stroke="#000000"
		stroke-width="20"
		stroke-linecap="square"
		stroke-linejoin="bevel"
		d="M32.071 90.7861C-2.49732 90.7861 13.9848 38.789 10.2162 19.9459C9.04881 14.1089 0 1.87607 0 0"
	/>
</svg>

<script>
  import { createEventDispatcher } from "svelte";
  import { getStaticSpecies } from "../../../store/filters/getStaticSpecies";
  import { speciesFilterChecked } from "../../../store/main";

  const dispatch = createEventDispatcher();

  $: checkedList = $speciesFilterChecked;

  const handleCheckboxClick = anId => {
    if (checkedList.includes(anId)) {
      checkedList = checkedList.filter(id => id !== anId);
    } else {
      checkedList = [...checkedList, anId];
    }
    dispatch("checkedListChanged", {
      checkedList,
    });
  };

  $: isChecked = filterType => {
    if (checkedList.length > 0) {
      return checkedList.includes(filterType.id);
    }
    return filterType.isChecked;
  };
</script>

<div>
  <hr class="my-3" />
  <div>
    <div class="text-xl font-medium p-1 my-2">Species</div>
    <div class="grid md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-3">
      {#if $getStaticSpecies}
        {#each $getStaticSpecies as filterType}
          <div class="m-1 flex">
            <input
              type="checkbox"
              checked={isChecked(filterType)}
              class="absolute hidden z-0"
            />
            <span
              on:click={() => handleCheckboxClick(filterType.id)}
              class="mr-2 top-1 w-6 h-6 max-w-6 max-h-6
              cursor-pointer border-2 border-gray-200 text-center"
            >
              {#if isChecked(filterType)}
                <i class="far fa-check relative bottom-2px" />
              {:else}
                <i />
              {/if}
            </span>
            <span class="inline">{filterType.name} </span>
          </div>
        {/each}
      {/if}
    </div>
  </div>
</div>

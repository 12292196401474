// Open Api Config

import {
  Configuration,
  SearchApi,
  StaticDataApi,
} from "@EMSXVET/emsx-fetch-map-app";

const baseURL = import.meta.env.VITE_EMSX_OPENAPI_URL;

export const getApiStaticData = (): StaticDataApi => {
  return new StaticDataApi(new Configuration({ basePath: baseURL }));
};

export const getApiSearch = (): SearchApi => {
  return new SearchApi(new Configuration({ basePath: baseURL }));
};

<script>
  import ListItem from "./ListItem.svelte";
  import { onMount } from "svelte";
  import { favouriteList } from "../../store/favourites/favouriteList";
  import { isFavHandlerReady } from "../../store/favourites/isFavHandlerReady";
  import axios from "axios";
  import { doesHttpOnlyCookieExist } from "../../utils/doesHttpOnlyCookieExist";

  export let sortedItems;
  const screenHeight = Math.floor(window.screen.height * 0.7);

  onMount(() => {
    if (!doesHttpOnlyCookieExist("sessionid")) return;
    axios
      .get("/ajax/student/favourites/", { withCredentials: true })
      .then(res => {
        res.data.forEach(favourite => {
          $favouriteList = [...$favouriteList, favourite.id];
        });
        $isFavHandlerReady = true;
      })
      .catch(error => {
        console.log(error);
      });
  });
</script>

<div id="slimeScrollContainerForListItem" class="z-20 overflow-scroll">
  {#each sortedItems as item}
    <ListItem {...item} />
  {/each}
  <div class="slimScrollerBottomBuffer" />
</div>

<style>
  #slimeScrollContainerForListItem {
    -ms-overflow-style: none;
    -moz-overflow-style: none;
    scrollbar-width: none;
    height: 700px !important;
  }

  #slimeScrollContainerForListItem::-webkit-scrollbar {
    display: none;
  }

  .slimScrollerBottomBuffer {
    width: 100%;
    height: 200px !important;
  }
</style>

import { derived } from "svelte/store";
import { favouriteList } from "./favouriteList";
import { isFavHandlerReady } from "./isFavHandlerReady";
import { doesHttpOnlyCookieExist } from "../../utils/doesHttpOnlyCookieExist";
import axios from "axios";

export const getFavourites = derived(
  [favouriteList],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ([$favouriteList], set) => {
    // check sessionid cookie is set!
    if (!doesHttpOnlyCookieExist("sessionid")) {
      set([]);
      return;
    }
    axios
      .get("/ajax/student/favourites/", { withCredentials: true })
      .then(res => {
        set(res.data);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isFavHandlerReady.update(_ => true);
      })
      .catch(error => {
        console.log(error);
      });
  },
  [],
);

<script>
  import { currentSortMethod } from "../../store/main"

  let dropdownShow = false
  let sortCategories = ["Nearest", "Name"]

  const toggleShowdropdown = () => {
    dropdownShow = !dropdownShow
  }

  const setCurrentSortValue = (category) => {
    $currentSortMethod = category
  }
</script>

<div class="relative">
  <button
    class="text-base py-2 px-4 rounded-full border-2 border-light-grey
                    outline-none focus:outline-none mr-1 mb-1
                    ease-linear transition-all duration-150"
    type="button"
    on:click={toggleShowdropdown}
  >
    {$currentSortMethod}
    <span class="text-md p-1 pl-3">
      <i class="far fa-chevron-down" />
    </span>
  </button>
  <div
    class="absolute border-2 border-light-grey md:w-full w-32 left-1/2 transform -translate-x-1/2 bg-white text-lg z-50 list-none text-left rounded mt-1  {dropdownShow
      ? 'block'
      : 'hidden'}"
  >
    {#each sortCategories as category}
      <div
        on:click={() => {
          setCurrentSortValue(category)
          toggleShowdropdown()
        }}
        class="text-base py-2 px-4 font-normal block w-full cursor-pointer hover:bg-light-grey"
      >
        {category}
      </div>
    {/each}
  </div>
</div>

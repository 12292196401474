import { derived } from "svelte/store";
import { getFavourites } from "./favourites/getFavourites";
import { currentSortMethod, isFavourites } from "./main";
import { searchPlacementByLatLong } from "./searchPlacementByLatLong";

export const sortedLocations = derived(
  [searchPlacementByLatLong, currentSortMethod, isFavourites, getFavourites],
  ([$locations, $method, $isfavourites, $favouriteList], set) => {
    if ($isfavourites) {
      $locations = $favouriteList;
    } else {
      if (!$locations || $locations === undefined) {
        set([]);
        return;
      }
    }

    if ($method === "Nearest") {
      const sortedByDistance = $locations.sort((a, b) => {
        return a.distance.m - b.distance.m;
      });
      set(sortedByDistance);
    } else {
      const sortedByName = $locations.sort((a, b) => {
        if (a.placement_provider_name < b.placement_provider_name) return -1;
        if (a.placement_provider_name > b.placement_provider_name) return 1;
        return 0;
      });
      set(sortedByName);
    }
  },
  [],
);

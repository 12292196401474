<script>
  import {
    placementTypesFiltersChecked,
    practiceInterestsFiltersChecked,
    speciesFilterChecked,
  } from "../../store/main";

  import PlacementTypeFilterCheckbox from "../common/checkboxes/PlacementTypeFilterCheckbox.svelte";
  import SpeciesFilterCheckbox from "../common/checkboxes/SpeciesFilterCheckbox.svelte";
  import PracticeInterestFilterCheckbox from "../common/checkboxes/PracticeInterestFilterCheckbox.svelte";

  export let showModal;

  let placementTypeCurrentFilters = [];
  let speciesCurrentFilters = [];
  let practiceInterestsCurrentFilters = [];

  const handleApplyFilterClick = () => {
    showModal = !showModal;
    $placementTypesFiltersChecked = placementTypeCurrentFilters;
    $speciesFilterChecked = speciesCurrentFilters;
    $practiceInterestsFiltersChecked = practiceInterestsCurrentFilters;
  };

  const placementTypeCheckedListChanged = event => {
    placementTypeCurrentFilters = event.detail.checkedList;
  };

  const speciesCheckedListChanged = event => {
    speciesCurrentFilters = event.detail.checkedList;
  };

  const practiceInterstsCheckedListChanged = event => {
    practiceInterestsCurrentFilters = event.detail.checkedList;
  };

  const handleClearAllCheckboxes = () => {
    $placementTypesFiltersChecked = [];
    $practiceInterestsFiltersChecked = [];
    $speciesFilterChecked = [];
  };
</script>

{#if showModal}
  <div
    class="fixed z-20 inset-0 overflow-y-auto"
    aria-labelledby="More Filters"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-60 text-center sm:block sm:p-0 border-4 border-blue-400"
    >
      <div
        class="fixed inset-0 bg-modal-grey bg-opacity-100 transition-opacity"
        aria-hidden="true"
      />
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-8/12 sm:w-full"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="grid grid-cols-2 justify-items-end">
            <div class="left-16 font-bold text-xl relative">More Filters</div>
            <button
              on:click={() => {
                showModal = false;
              }}
              class="p-1 outline-none border-none w-10 font-bold">✕</button
            >
          </div>

          <PlacementTypeFilterCheckbox
            on:checkedListChanged={placementTypeCheckedListChanged}
          />

          <SpeciesFilterCheckbox
            on:checkedListChanged={speciesCheckedListChanged}
          />

          <PracticeInterestFilterCheckbox
            on:checkedListChanged={practiceInterstsCheckedListChanged}
          />

          <hr class="my-3" />
          <div class="grid grid-cols-2 mt-12">
            <div
              class="justify-self-start outline-none underline font-medium cursor-pointer"
              on:click={handleClearAllCheckboxes}
            >
              Clear all
            </div>
            <button
              on:click={handleApplyFilterClick}
              class="justify-self-end secondary-button"
              >Apply filters
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

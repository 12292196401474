import {
  placementTypesFiltersChecked,
  speciesFilterChecked,
  practiceInterestsFiltersChecked,
  zoomToLocation,
  mapCenterLatLng,
} from "../store/main";
import { parseQueryStringFromUrl } from "./parseQueryStringFromUrl";

// QueryString should expect:  etc/map/lat/lng/placementTypes/species/practiceInterests
export const centerMapOnUrlQueryData = (): void => {
  if (history.state !== null) return;
  const currentPageUrl = window.location.pathname;
  if (currentPageUrl.search("map/") === -1) return; // map url data not provided
  const queryString = currentPageUrl.split("map/")[1];
  const parsedQS = parseQueryStringFromUrl(queryString);
  placementTypesFiltersChecked.update(() => parsedQS.placementTypes);
  speciesFilterChecked.update(() => parsedQS.species);
  practiceInterestsFiltersChecked.update(() => parsedQS.practiceInterests);
  zoomToLocation.update(() => true);
  mapCenterLatLng.update(() => parsedQS.coordinates);
};

import { derived } from "svelte/store";
import { address } from "./main";
import { getApiSearch } from "./openApiConfig";
import type { SearchGeoLocationApiSearchGeoLocationPartialAddressGetRequest } from "@EMSXVET/emsx-fetch-map-app";

export const getGeoLocationByAddress = derived([address], ([$address], set) => {
  const response = {
    formattedAddress: "",
    geometry: {
      lat: 120,
      lng: 0,
    },
    error: null,
  };
  if ($address) {
    const p: SearchGeoLocationApiSearchGeoLocationPartialAddressGetRequest = {
      partialAddress: $address,
    };
    getApiSearch()
      .searchGeoLocationApiSearchGeoLocationPartialAddressGet(p)
      .then(res => {
        response.formattedAddress = res.formatted_address;
        response.geometry.lat = res.latitude;
        response.geometry.lng = res.longitude;
        set(response);
      })
      .catch(err => {
        console.log(err);
      });
  }
  set(response);
});
